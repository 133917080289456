@import 'styles/config';

.editor {
    width: 100%;
    border-radius: 6px;
    // overflow: hidden;
    border: scale-to-em(1.5px) solid var(--color-border);
    position: relative;
    background-color: $light-gray;

    * {
        margin-bottom: 0px;
    }

    :global(.slate-ToolbarButton) {
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        width: 22px;

        height: 20px;

        position: relative;
        margin: 0;
    }

    // input {
    //     display: none;
    // }

    :global(.slate-HeadingToolbar) {
        margin: 0px;
        padding: 0px scale-to-em(8);
        border: 0px;
        background-color: $white;
        gap: scale-to-em(0);
        padding-left: scale-to-em(14);
        min-height: 34px;
        z-index: 1;
    }

    path {
        fill: #979797;
    }

    :global(.slate-ToolbarButton-active) {
        path {
            fill: $dark-gray !important;
        }
    }

    > div:nth-child(2) {
        padding: 8px 16px 20px 32px !important;
        min-height: 300px !important;
    }

    :global(.slate-Draggable-blockToolbarWrapper) {
        align-self: center;
    }
}

.textarea_container {
    // div:first-of-type {
    //     margin: 0;
    // }
    .error_msg {
        height: fit-content;
        margin-top: 4px;
        min-height: 30px;

        color: $color-error;
    }
}

.inputError {
    border: $color-error 1.5px solid;
}

//Plate tooltip
.infoContainer {
    position: static;
    height: 12px !important;
    min-height: 0 !important;
    background-color: transparent !important;
    padding: 0 0 20px 0 !important;
    // Makes position static when on phones and makes label relative
    @media (max-width: $max-mobile) {
        position: static;
    }
}
@media (pointer: coarse) {
    .active {
        button {
            path {
                fill: $dark-amber !important;
            }
        }
    }
}

.infoBox {
    position: absolute;
    padding: scale-to-em(24);
    font-style: italic;
    background: $white !important;
    min-height: 0 !important;
    top: 0;
    max-width: 100%;
    left: scale-to-em(60);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    opacity: 0;
    z-index: 1;
    transition: all 150ms ease-in-out;
    visibility: hidden;
    @media (max-width: $max-mobile) {
        .active & {
            visibility: visible;
            opacity: 1;
        }
    }
}

.iconButton {
    cursor: pointer;
    // height: scale-to-em(15) !important;
    // width: scale-to-em(15) !important;

    @media (max-width: $max-mobile) {
        max-width: 100%;
        justify-self: flex-start;
    }
}

@media (hover: hover) and (pointer: fine) {
    .editor {
        :global(.slate-ToolbarButton) {
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: pointer;
            vertical-align: middle;
            width: 22px;
            height: 20px;

            position: relative;
            margin: 0;

            &:after {
                content: '';
                top: 0px;
                position: absolute;
                width: scale-to-em(20);
                height: scale-to-em(20);
                border-radius: 2px;
                background-color: rgba(194, 214, 214, 0.4);
                opacity: 0;
                transition: all 100ms ease-in-out;
            }
        }

        :global(.slate-ToolbarButton:hover) {
            // display: none;
            &:after {
                opacity: 1;
            }
        }
    }
}

.obituaryVideo {
    // pointer-events: none;
    // display: none;
    margin: 14px 0px;
}

.video {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 60px;
}

.help {
    margin: 0px !important;
    align-self: end;
    position: absolute;
    right: -10px;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    p {
        margin: 0px;
    }
}

.tooltipContainer {
    [class*='infoBox'] {
        width: 300px !important;

        @media (max-width: $max-mobile) {
            width: 220px !important;
            position: absolute;
            // transform: translate(-50%, 0%);
            left: -285%;
            max-width: 220px;
            z-index: 999999999;
        }
    }
}

.tooltip {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.command {
    display: flex;

    gap: 0.5em;
    strong {
        font-weight: 500 !important;
        font-style: normal;
    }

    svg {
        margin-top: 5px;
        width: 10px;
    }
}

.tippy {
    font-size: 12px;
    background-color: #666;
    color: white;
    padding: 2px 8px 0px 8px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%, 0%);

    opacity: 0;
}

.tippyContainer {
    position: relative;
    @media (pointer: fine) {
        &:hover {
            .tippy {
                opacity: 0.8;
            }
        }
    }
}
