@import 'styles/config';

.center {
    text-align: center;
}

.date {
    // font-size: 16px;
    font-weight: 500;
    color: var(--medium-gray);
    margin: 14px 0px 14px 0px;
}

.error {
    @include responsive-font(12px, 14px);
    color: $color-error !important;
    margin: 0px;
}

.p {
    margin: 14px 0px 14px 0px;
}

.p-18 {
    @include responsive-font($p-18-size-min, $p-18-size);
    margin: 14px 0px 14px 0px;
}

.p-small {
    @include responsive-font(14px, 14px);
    margin: 0px;
}

.h1 {
    @include h1;
    margin: 0px;
}

.h2 {
    @include h2;
    margin: 0px;
}

.h3 {
    @include h3;
    margin: 0px;
}

.h4 {
    @include h4;
    margin: 0px;
}

.h5 {
    @include h5;
    margin: 0px;
}

.h6 {
    margin: 14px 0px 14px 0px;

    @include h6;
    margin: 0px;
}
