@import 'styles/config';

.burger {
    align-self: center;
    width: 60px;
    height: 60px;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */

    &:hover {
        .line .after {
            animation-name: move;
            animation-timing-function: ease;
            animation-duration: 1s;
        }
    }
}

.container {
    margin: auto;
    display: block;
    width: 34px;
    height: 15px;
    position: relative;
}

.line {
    border-radius: 10px;
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    transition: transform 0.5s $default-ease;

    &__one {
        bottom: auto;
    }

    &__two {
        top: auto;

        .after {
            animation-delay: 0.2s;
        }
    }

    .after {
        border-radius: 10px;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $dark-gray;
        transition: background-color 0.5s $default-ease;
    }
}

.open {
    .line {
        background-color: rgba(255, 255, 255, 0.2);
        .after {
            background-color: $white;
        }
    }
    .line__one {
        transform: translateY(6.5px) rotate(45deg);
    }

    .line__two {
        transform: translateY(-6.5px) rotate(-45deg);
    }

    .anim {
        background-color: $white;
    }
}

.anim {
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;

    margin: auto;
    background-color: $dark-gray;
    transition: background-color 0.5s $default-ease;

    &__one {
        bottom: auto;
    }

    &__two {
        top: auto;
    }
}
