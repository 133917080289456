@import 'styles/config';

.footer {
    @include section(40, 40, 25, 25);
    background-color: $color-background;
    position: relative;
}

.container {
    // justify-items: center;
    justify-content: center;

    svg {
        justify-self: center;
    }
}

.links {
    @include grid-item(10, 1);

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    align-items: center;
    color: $medium-gray;
    margin-top: scale-to-em(78);
    z-index: 1;

    // @media (max-width: $max-desktop) {
    //     margin-top: scale-to-em(64);
    //     justify-content: space-between;
    // }

    @media (max-width: $max-desktop) {
        @include grid-item();
        margin-top: scale-to-em(48);
        justify-content: center;
        flex-direction: column;
        gap: scale-to-em(15);
    }

    &__buttons {
        display: flex;
        gap: scale-to-em(12);
        order: 3;

        @media (max-width: $max-desktop) {
            justify-content: center;
            align-items: center;
            order: 5;
            margin-top: scale-to-em(16);
        }
    }

    &__container {
        display: flex;
        flex: 1;
        justify-content: center;
        a,
        p {
            opacity: 0.9;
            font-size: 16px;
            text-align: center;
        }
        // @media (max-width: $max-desktop) {
        //     flex: auto;
        // }
        @media (max-width: $max-tablet) {
            flex-direction: column;
            align-items: center;
            a,
            p {
                opacity: 1;
                color: $dark-gray;
                font-size: 18px;
            }
        }
        @media (hover: hover) and (pointer: fine) {
            a:hover {
                opacity: 1;
                color: $dark-gray;
                transition: opacity 0.5s ease-in-out;
            }
        }
    }

    .infoPage {
        order: 1;
    }

    .services {
        order: 2;
    }

    .aboutUs {
        order: 4;
        @media (max-width: $max-desktop) {
            order: 3;
        }
    }

    .policy {
        order: 5;
        @media (max-width: $max-desktop) {
            order: 4;
        }
    }

    .copyright {
        order: 6;
        min-width: 100%;
        justify-content: center;
        margin-top: scale-to-em(32);
        @media (max-width: $max-desktop) {
            margin-top: scale-to-em(64);
        }
    }
}

.line {
    @include grid-item();
    width: 100%;
    height: 2px;
    background: rgba(238, 235, 231, 1);
    margin-bottom: 75px;
    z-index: 1;
}

.icon {
    height: 65px;
    width: 65px;
}

.logo {
    @include grid-item(8, 2);
    margin-bottom: 25px !important;
    z-index: 1;
    @media (max-width: $max-tablet) {
        @include grid-item();
    }
}

.background {
    border-radius: 0;
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 100%;
    min-width: 1800px;
    width: 100%;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

    z-index: 0;
}
