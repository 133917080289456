@import 'styles/config';

.nav {
    @include fit(fixed);
    contain: layout;
    pointer-events: none;
    z-index: 2;
    height: 100vh;

    &__inner {
        display: flex;
        justify-content: flex-end;

        width: 100vw;
        height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: none;

        position: relative;
        z-index: 1;

        padding: var(--gutter);
        // padding-top: scale-to-em(200);
        // padding-bottom: scale-to-em(230);
        justify-content: center;

        height: 100%;
        width: 100%;

        pointer-events: auto;

        background: transparent;
        transform: translate3d(0, -105%, 0);
        transition: transform var(--transition-duration) var(--default-ease),
            background var(--transition-duration) var(--default-ease);

        @media (max-width: $max-mobile) {
            // padding same as navbar height in mobiles
            padding-top: scale-to-em(60);
            padding-bottom: scale-to-em(60);
        }

        .open & {
            transform: none;
            background: $dark-amber;
        }
    }

    &__backdrop {
        @include fit;
        z-index: 0;

        width: 100%;

        opacity: 0;
        transition: opacity var(--transition-duration) var(--default-ease);

        background: #000;

        .open & {
            pointer-events: auto;

            opacity: 0.3;
        }
    }

    &__list {
        height: 70vh;
        max-height: 740px;
        display: grid;
        grid-template-rows: repeat(1fr, 5);
        justify-content: center;
        align-items: center;

        @media (max-width: $max-mobile) {
            height: 85%;
            max-height: 480px;
            transform: translateY(-5%);
            gap: 1em;

            // li {
            //     margin: 1em 0;
            // }
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                .nav__item {
                    opacity: 0.55;
                }
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
            padding: 10px 0px;
            gap: 12px;

            svg {
                filter: invert(100%) sepia(88%) saturate(1%) hue-rotate(65deg)
                    brightness(109%) contrast(101%);
            }
        }
    }

    &__item {
        transition: all 550ms var(--default-ease);
        margin: auto;
        a > div {
            box-sizing: border-box;
        }

        &:hover {
            opacity: 1 !important;
            transition: all 250ms var(--default-ease);
        }
    }
}

.navlinkMotion {
    transform: translateY(100%);
}
