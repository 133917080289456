@import 'styles/config';

.searchBar {
    @include grid-item(6, 3);

    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 1em 1em 1em 1.5em;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.4);

    @media (max-width: $max-desktop) {
        // @include grid-item();
    }

    @media (min-width: $min-tablet) {
    }

    input[type='text'] {
        @include responsive-font(13px, 14px);
        border: none;
        height: 24px;
        min-height: none;
        width: 100%;
        letter-spacing: 1px;
        font-weight: 500;
        border-radius: 0px;
        background: transparent;
        margin: 0;

        &::placeholder {
            color: $medium-gray;
        }
        color: $dark-gray;

        &:focus {
            outline: none;
            &::placeholder {
                color: $dark-gray;
            }
        }
    }

    & > svg {
        min-width: 24px;
        margin: 0.5em;
    }

    transition: all 0.3s $ease-out-cubic;
}

.loading {
    svg {
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }
}

.focus {
    background-color: rgb(255, 255, 255);
    input {
        color: $dark-gray;
    }
}

.name {
    display: flex;
    align-items: center;
    width: 100%;
}

@media (max-width: $max-desktop + 0.5px) {
    .searchBar {
        display: none;
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    40% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
}
