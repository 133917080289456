@import 'styles/config';

.layout {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;

    min-height: 100vh;
    width: 100vw;

    @media (max-width: $max-desktop) {
        // header fixed in mobile, create space for it
        padding-top: $header-height-mobile;
        padding-top: var(--header-height);
    }

    &__content {
        flex-grow: 1;
    }
}
