@font-face {
    font-family: 'GT Super Display';
    src: local('GT Super Display'),
        url(../public/fonts/GT_Super/GT-Super-Display-Regular.woff2)
            format('woff2');

    src: url(../public/fonts/GT_Super/GT-Super-Display-Regular.woff2)
            format('woff2'),
        url(../public/fonts/GT_Super/GT-Super-Display-Regular.woff)
            format('woff'),
        url('../public/fonts/GT_Super/GT-Super-Display-Regular.ttf?')
            format('truetype');
}

@font-face {
    font-family: 'Silka';
    src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-black-webfont.eot);
    src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-black-webfont.eot?#iefix)
            format('embedded-opentype'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-black-webfont.woff2)
            format('woff2'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-black-webfont.woff)
            format('woff'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-black-webfont.ttf)
            format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Silka';
    src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-bold-webfont.eot);
    src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-bold-webfont.eot?#iefix)
            format('embedded-opentype'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-bold-webfont.woff2)
            format('woff2'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-bold-webfont.woff)
            format('woff'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-bold-webfont.ttf)
            format('truetype');
    font-weight: 700;
    font-style: normal;
}

// @font-face {
//     font-family: 'Silka';
//     src: url('silka-extralight-webfont.eot');
//     src: url('silka-extralight-webfont.eot?#iefix') format('embedded-opentype'),
//         url('silka-extralight-webfont.woff2') format('woff2'),
//         url('silka-extralight-webfont.woff') format('woff'),
//         url('silka-extralight-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
    font-family: 'Silka';
    src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-light-webfont.eot);
    src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-light-webfont.eot?#iefix)
            format('embedded-opentype'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-light-webfont.woff2)
            format('woff2'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-light-webfont.woff)
            format('woff'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-light-webfont.ttf)
            format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Silka';
    // src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-medium-webfont.eot);
    src: 
    // url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-medium-webfont.eot?#iefix)
    //         format('embedded-opentype'),
        // url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-medium-webfont.woff2)
        // format('woff2');
    // url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-medium-webfont.woff)
    //     format('woff'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-medium-webfont.ttf)
        format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Silka';
    src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-regular-webfont.eot);
    src: url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-regular-webfont.eot?#iefix)
            format('embedded-opentype'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-regular-webfont.woff2)
            format('woff2'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-regular-webfont.woff)
            format('woff'),
        url(../public/fonts/silka-complete-webfont/Silka-Roman-Webfont/silka-regular-webfont.ttf)
            format('truetype');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'Silka';
//     src: url('silka-semibold-webfont.eot');
//     src: url('silka-semibold-webfont.eot?#iefix') format('embedded-opentype'),
//         url('silka-semibold-webfont.woff2') format('woff2'),
//         url('silka-semibold-webfont.woff') format('woff'),
//         url('silka-semibold-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Silka';
//     src: url('silka-thin-webfont.eot');
//     src: url('silka-thin-webfont.eot?#iefix') format('embedded-opentype'),
//         url('silka-thin-webfont.woff2') format('woff2'),
//         url('silka-thin-webfont.woff') format('woff'),
//         url('silka-thin-webfont.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }
