@import 'styles/config';

.navlink {
    color: $white;
    text-align: center;
    // font-size: 5vh;
    // padding: 20px 0px;

    // @media (max-width: $max-mobile) {
    //     padding: 15px 0px;
    // }
}

.overflow {
    overflow: hidden;
}

.navlinkMotion {
    transform: translateY(100%);
}
