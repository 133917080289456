@import 'styles/config';

.container {
    align-self: center;
    display: flex;
    align-content: center;
    position: relative;
    & > button {
        &:active {
            opacity: 1;
        }
    }

    svg {
        display: flex;
        align-self: center;
    }

    @media (min-width: $min-tablet) {
        height: var(--header-height);
        &:hover .buttonContainer {
            opacity: 1;
            visibility: visible;
            transition: all 0.2s $ease-out-cubic;
        }
    }
}

.open {
    @media (max-width: $max-tablet) {
        .buttonContainer {
            opacity: 1;
            visibility: visible;
            transition: all 0.2s $ease-out-cubic;
        }
    }
}

.buttonContainer {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 0%;
    top: 100%;
    background-color: $white;
    width: scale-to-em(160);
    border-radius: 8px;
    padding: scale-to-em(12) 0px;
    transition: all 0.2s $ease-out-cubic;
    margin-top: scale-to-em(-6);
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    @media (max-width: $max-tablet) {
        left: 50%;
        top: 120%;

        transform: translate(-50%, 0%);
        width: scale-to-em(200);
    }
}

.button {
    margin: 0px;
    width: 100% !important;
    height: scale-to-em(60) !important;
    border: none;
    padding: scale-to-em(24);
    transition: all 0.1s $ease-out-cubic;
    position: relative;
    overflow: hidden;
    display: flex !important;
    align-items: center;
    justify-content: center;

    @media (max-width: $max-tablet) {
        height: scale-to-em(80);
    }
    div {
        width: 100%;
        position: relative;
        z-index: 1;
        transition: all 200ms var(--default-ease);
    }

    &:hover {
        div {
            color: white;
        }
        &:after {
            right: -10px;
        }
    }

    &:after {
        transform: none !important;
        background: $dark-amber;
        content: '';
        height: 220px;
        width: 280px;
        filter: blur(2px);
        right: 200px;
        bottom: -10px;
        position: absolute;
        transition: all 550ms var(--default-ease) !important;
        z-index: 0;
    }
}
