@import 'styles/config';

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindex-header;
    width: 100vw;
    @media (max-width: $max-desktop) {
        padding-right: var(--browser-scrollbar-width);

        &__open {
            padding-right: var(--browser-scrollbar-width);
        }
    }

    svg path {
        transition: fill var(--transition-duration) var(--default-ease);
    }

    @media (min-width: $min-desktop) and (pointer: fine) {
        opacity: 1;
        transform: translateY(-100%);
    }

    transition: height var(--transition-duration) var(--default-ease),
        background-color 500ms var(--default-ease),
        top var(--transition-duration) var(--default-ease),
        transform var(--transition-duration) var(--default-ease),
        opacity var(--transition-duration) var(--default-ease);

    @media (hover: hover) and (pointer: fine) {
        top: calc(var(--header-height) * -1);
    }

    &__skip {
        position: absolute;
        top: 0;

        transform: translateY(-100%);

        &:focus {
            transform: none;
        }
    }

    &__container {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        height: var(--header-height);
    }

    &__controls {
        @include grid-item(3, 9);
        justify-self: end;
        display: flex;
        align-items: center;
        gap: scale-to-em(10);
        z-index: 2;
        height: var(--header-height);
        @media (max-width: $max-mobile) {
            gap: scale-to-em(0);
        }

        .search_icon {
            height: 50px;
            width: 50px;
            display: none;

            @media (max-width: $max-desktop) {
                display: flex;
                align-self: center;
                justify-content: center;
                align-items: center;
            }
            opacity: 1;
            visibility: visible;
            transition: all var(--transition-duration) var(--default-ease);
            &_off {
                opacity: 0;
                visibility: hidden;
            }
            @media (max-width: 360px) {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__search {
        align-self: center;
        border: 1px solid rgba(202, 202, 202, 0);

        @media (max-width: $max-mobile) {
            // gap: scale-to-em(16);
            background: none !important;
        }
    }

    &__search_outline {
        border: 1px solid rgba(202, 202, 202, 0.4);
    }

    &__search_off {
        opacity: 0;
        visibility: hidden;
    }

    &__logo {
        @include grid-item(3, 0);
        align-self: center;
        width: fit-content;

        display: flex;
        align-items: center;
        @media (max-width: $max-tablet) {
            @include grid-item(4, 0);

            svg {
                height: 38px;
                width: auto;
            }
        }
        z-index: 2;
    }
}

.stick {
    @media (min-width: $min-desktop) {
        height: var(--header-height);
    }
}

.showHeader {
    position: fixed;
    opacity: 1;
    @media (hover: hover) and (pointer: fine) {
        transform: translateY(0);
    }

    @media (hover: hover) and (pointer: fine) {
        top: 0%;
    }
}

.accountIcon {
    display: none !important;
    align-self: center;
    align-content: center;
    margin-top: 2px;
    height: 50px;
    width: 50px;
    @media (max-width: $max-tablet) {
        display: flex !important;
        align-self: center;
        justify-content: center;
        align-items: center;
    }
}

.accountButton {
    align-self: center;
    @media (max-width: $max-tablet) {
        display: none !important;
    }
}

.header__open {
    path {
        fill: $white;
    }
}

.background {
    height: var(--header-height);
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    position: fixed;
    z-index: 2;
    transition: height var(--transition-duration) var(--default-ease),
        background-color 500ms var(--default-ease),
        top var(--transition-duration) var(--default-ease),
        opacity var(--transition-duration) var(--default-ease);
    // byrja uppi á tölvum
    top: 0;
    @media (hover: hover) and (pointer: fine) {
        top: calc(var(--header-height) * -1);
    }
}

.showBg {
    position: fixed;
    @media (hover: hover) and (pointer: fine) {
        top: 0%;
    }
}

.whiteBg {
    background-color: rgba(255, 255, 255, 0.9);
}
